import { useEffect } from 'react';
import { entityRegistrationData } from 'core/helpers/entities/entry.form-data';
import { useForm } from 'core/hooks/form/useForm';
import { toShortDate } from 'core/services/date';

import { InputCheckbox, Select } from 'components/Form';

const TrainingDay = ({ day, order, toppings, setOrder }) => {
	const form = entityRegistrationData(toppings, order);
	const { register, watch, getValue, isValid } = useForm();

	const valid = isValid();
	const values = watch();

	useEffect(() => {
		setOrder(prev => [
			...prev.filter(item => item.date !== day.date),
			{
				date: day.date,
				...(!day.bread
					? {}
					: values.bread
						? { bread: values.bread }
						: { ...values, bread: values.bread }),
				isValid: values.bread ? true : valid,
			},
		]);
	}, [values, valid]);

	const findTopping = () => {
		return toppings.find(x => x.id === getValue('topping'));
	};

	return (
		<div className='training-day'>
			{day.bread || day.reason ? (
				<>
					<h2>{toShortDate(day.date)}</h2>
					{day.bread ? (
						<>
							<InputCheckbox {...register('bread', form.bread)} />

							{!getValue('bread') ? (
								<>
									<Select
										{...register('topping', {
											...form.topping,
											description: getValue('topping')
												? findTopping()?.description
												: '',
										})}
									/>
									<Select {...register('size', form.size)} />

									{getValue('topping') &&
									findTopping()?.hasOptions ? (
										<InputCheckbox
											{...register('extra', form.extra)}
										/>
									) : null}
								</>
							) : null}
						</>
					) : (
						day.reason
					)}
				</>
			) : null}
		</div>
	);
};

export default TrainingDay;
