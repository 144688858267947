import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getEntityTypeDetails } from 'core/helpers/entities/entity.helper';
import { useSubmit } from 'core/hooks/submit/useSubmit';
import { toShortDate } from 'core/services/date';
import { downloadFile } from 'core/services/file';
import {
	loadEntity,
	updateAttendancy,
} from 'core/store/entities/entity.actions';

import Card from 'components/Card/Card';
import { InputCheckbox } from 'components/Form';
import { AdminHeader } from 'components/Layouts/AdminLayout/AdminHeader/AdminHeader';
import Loading from 'components/Loading/Loading';

const EntityAttendancy = props => {
	const dispatch = useDispatch();
	const { handleSubmit } = useSubmit();
	const { id } = useParams();

	const { entitiesLoading, entity } = useSelector(
		state => state.entityReducer,
	);

	useEffect(() => {
		dispatch(loadEntity(props.entity, id));
	}, [dispatch]);

	const handleUpdate = async (userId, date, presence) => {
		await handleSubmit(
			updateAttendancy({
				userId,
				date,
				presence,
				entryId: entity.entryId,
				type: props.entity,
			}),
		);
	};

	const downloadSubscriptions = async () => {
		downloadFile(
			`at_${props.entity}_${id}`,
			'application/pdf',
			`aanwezigheden_${entity.slug}.pdf`,
		);
	};

	return entitiesLoading ? (
		<Loading />
	) : (
		<div>
			<AdminHeader
				title='Aanwezigheden'
				optional={{
					action: downloadSubscriptions,
					label: <FontAwesomeIcon icon={faDownload} />,
				}}
			/>

			<Card
				title='Aanwezigheden'
				footer={
					<div
						className='btn__group full--flex'
						style={{ width: '50%' }}>
						<Link
							className='btn btn--secondary btn--small'
							to={`/${getEntityTypeDetails(props.entity).name.toLowerCase()}`}>
							Overzicht
						</Link>
						<Link
							className='btn btn--secondary btn--small'
							to={`/${getEntityTypeDetails(props.entity).name.toLowerCase()}/${id}`}>
							Detail
						</Link>
						<Link
							className='btn btn--secondary btn--small'
							to={`/${getEntityTypeDetails(props.entity).name.toLowerCase()}/${id}/registraties`}>
							Inschrijvingen
						</Link>
					</div>
				}
				withShadow>
				<table className='admin-permissions'>
					<thead>
						<tr>
							<th>Naam</th>
							{entity?.days.map(day => (
								<th
									key={`role-${day.date}`}
									style={{
										width: '9.3rem',
										textAlign: 'center',
									}}>
									{toShortDate(day.date)}
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{entity?.invitees.filter(x => x.confirmed).length ? (
							entity?.invitees
								.filter(x => x.confirmed)
								?.map((user, i) => (
									<tr key={`attendance-${user.id}-${i}`}>
										<td>
											<b>
												{user.firstName} {user.lastName}
											</b>
											<br />
											{user.email}
										</td>

										{entity?.days.map(day => {
											const presence =
												user.presences?.find(
													x =>
														new Date(
															day.date,
														).getTime() ===
														new Date(
															x.date,
														).getTime(),
												);

											return (
												<td
													key={`user-${day.date}-${user.id}`}
													valign='center'
													style={{
														width: '9.3rem',
														textAlign: 'center',
														verticalAlign: 'middle',
													}}>
													<InputCheckbox
														id={`user_${day.date}-${user.id}`}
														onChange={() =>
															handleUpdate(
																user.id,
																day.date,
																presence
																	? !presence.presence
																	: true,
															)
														}
														setTouched={() => ''}
														name=''
														value={
															presence
																? presence.presence
																: false
														}
														style={{
															marginBottom: 0,
														}}
													/>
												</td>
											);
										})}
									</tr>
								))
						) : (
							<div className='empty-list'>
								Geen data om weer te geven
							</div>
						)}
					</tbody>
				</table>
			</Card>
		</div>
	);
};

export default EntityAttendancy;
