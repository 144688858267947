import React from 'react';
import {
	Dashboard,
	EntityDetail,
	EntityOverview,
	EntityRegistrations,
	Instructors,
	MovingMissionDetail,
	MovingMissionOverview,
	MovingMissionResults,
	Permissions,
	Roles,
	WellBeingDetail,
	WellBeingOverview,
} from 'views';
import EntityAttendancy from 'views/Entity/EntityAttendancy';
import MailBox from 'views/MailBox/MailBox';

import NotFound from 'components/Error/NotFound/NotFound';
import New from 'components/MailBox/New/New';
import Send from 'components/MailBox/Send/Send';
import Star from 'components/MailBox/Star/Star';
import Trash from 'components/MailBox/Trash/Trash';

export const routesConfig = [
	{
		path: '/',
		element: <Dashboard />,
		permissions: [],
		exact: true,
		index: true,
	},
	{
		path: '/dashboard',
		element: <Dashboard />,
		permissions: [],
		exact: true,
	},
	{
		path: '/welzijn',
		element: <WellBeingOverview />,
		permissions: ['well_being_admin'],
		exact: true,
	},
	{
		path: '/welzijn/toevoegen',
		element: <WellBeingDetail />,
		permissions: ['well_being_add'],
		exact: true,
	},
	{
		path: '/welzijn/:id',
		element: <WellBeingDetail />,
		permissions: ['well_being_update'],
		exact: true,
	},

	{
		path: '/nieuws',
		element: <EntityOverview entity='news' />,
		permissions: ['news_admin'],
		exact: true,
	},
	{
		path: '/nieuws/toevoegen',
		element: <EntityDetail entity='news' />,
		permissions: ['news_add'],
		exact: true,
	},
	{
		path: '/nieuws/:id',
		element: <EntityDetail entity='news' />,
		permissions: ['news_update'],
		exact: true,
	},
	{
		path: '/nieuws/:id/registraties',
		element: <EntityRegistrations entity='news' />,
		permissions: ['news_see_results'],
		exact: true,
	},

	{
		path: '/evenementen',
		element: <EntityOverview entity='events' />,
		permissions: ['events_admin'],
		exact: true,
	},
	{
		path: '/evenementen/toevoegen',
		element: <EntityDetail entity='events' />,
		permissions: ['events_add'],
		exact: true,
	},
	{
		path: '/evenementen/:id',
		element: <EntityDetail entity='events' />,
		permissions: ['events_update'],
		exact: true,
	},
	{
		path: '/evenementen/:id/registraties',
		element: <EntityRegistrations entity='events' />,
		permissions: ['events_see_results'],
		exact: true,
	},
	{
		path: '/evenementen/:id/aanwezigheden',
		element: <EntityAttendancy entity='events' />,
		permissions: ['events_see_results'],
		exact: true,
	},

	{
		path: '/opleidingen',
		element: <EntityOverview entity='trainings' />,
		permissions: ['trainings_admin'],
		exact: true,
	},
	{
		path: '/opleidingen/toevoegen',
		element: <EntityDetail entity='trainings' />,
		permissions: ['trainings_add'],
		exact: true,
	},
	{
		path: '/opleidingen/:id',
		element: <EntityDetail entity='trainings' />,
		permissions: ['trainings_update'],
		exact: true,
	},
	{
		path: '/opleidingen/:id/registraties',
		element: <EntityRegistrations entity='trainings' />,
		permissions: ['trainings_see_results'],
		exact: true,
	},
	{
		path: '/opleidingen/:id/aanwezigheden',
		element: <EntityAttendancy entity='trainings' />,
		permissions: ['trainings_see_results'],
		exact: true,
	},

	{
		path: '/moving-mission',
		element: <MovingMissionOverview />,
		permissions: ['moving_mission_admin'],
		exact: true,
	},
	{
		path: '/moving-mission/toevoegen',
		element: <MovingMissionDetail />,
		permissions: ['moving_mission_add'],
		exact: true,
	},
	{
		path: '/moving-mission/:id/registraties',
		element: <MovingMissionResults />,
		permissions: ['moving_mission_see_results'],
		exact: true,
	},
	{
		path: '/moving-mission/:id',
		element: <MovingMissionDetail />,
		permissions: ['moving_mission_update'],
		exact: true,
	},

	{
		path: '/permissies',
		element: <Permissions />,
		permissions: ['permissions_admin'],
		exact: true,
	},
	{
		path: '/gebruikersrollen',
		element: <Roles />,
		permissions: ['roles_admin'],
		exact: true,
	},
	{
		path: '/lesgevers',
		element: <Instructors />,
		permissions: ['instructors_admin'],
		exact: true,
	},

	{
		path: '/mailbox',
		element: <MailBox />,
		permissions: ['see_mailbox'],
		exact: true,
		children: [
			{
				path: 'verzonden',
				index: true,
				replace: true,
			},
			{
				path: 'nieuw',
				element: <New />,
				permissions: ['see_mailbox'],
				exact: true,
			},
			{
				path: 'verzonden',
				element: <Send />,
				permissions: ['see_mailbox'],
				exact: true,
			},
			{
				path: 'prullenbak',
				element: <Trash />,
				permissions: ['see_mailbox'],
				exact: true,
			},
			{
				path: 'met-ster',
				element: <Star />,
				permissions: ['see_mailbox'],
				exact: true,
			},
		],
	},

	{
		path: '*',
		element: <NotFound />,
		permissions: [],
		exact: true,
	},
];
