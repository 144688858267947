import { ACTION_TYPES } from 'core/constants/action.constants/action.constants';
import { dispatchAction } from 'core/services/actions';

export const loadMailbox = (entity, id) => () => {
	dispatchAction({
		type: ACTION_TYPES.MAILBOX_LOAD,
		payload: { mailboxesLoading: true },
		meta: {
			action: {
				effect: {
					url: `/admin/${entity}/${id}/mailbox`,
					method: 'get',
				},
				commit: { type: ACTION_TYPES.MAILBOX_LOAD_COMMIT },
			},
			followup: [],
		},
	});
};

export const sendMailbox = (body, entity, id, navigate) => () => {
	dispatchAction({
		type: ACTION_TYPES.MAILBOX_POST,
		payload: {},
		meta: {
			action: {
				effect: {
					url: `/admin/${entity}/${id}/mailbox`,
					method: 'post',
					body: body,
				},
				commit: { type: ACTION_TYPES.MAILBOX_POST_COMMIT },
			},
			followup: [
				() =>
					navigate(`/mailbox/verzonden`, {
						state: { type: entity, id: id },
					}),
			],
		},
	});
};
