import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getEntityRegistrationFilter } from 'core/helpers/entities/entity.helper';
import { downloadFile } from 'core/services/file';
import {
	loadEntity,
	loadRegistrations,
} from 'core/store/entities/entity.actions';

import {
	EventRegistration,
	NewsRegistration,
	TrainingRegistration,
} from 'components/Entity/Registrations';
import { AdminHeader } from 'components/Layouts/AdminLayout/AdminHeader/AdminHeader';
import Loading from 'components/Loading/Loading';

const EntityRegistrations = props => {
	const filterOptions = getEntityRegistrationFilter();

	const { id } = useParams();

	const dispatch = useDispatch();

	const { entitiesLoading, entity, registrations } = useSelector(
		state => state.entityReducer,
	);

	const [filter, setFilter] = useState(filterOptions[0]);
	const [filteredList, setFilteredList] = useState([]);

	useEffect(() => {
		handleFilter(filter);
	}, [registrations]);

	useEffect(() => {
		dispatch(loadEntity(props.entity, id));
		dispatch(loadRegistrations(props.entity, id));
	}, [dispatch]);

	const handleFilter = filter => {
		setFilter(filter);

		let list = registrations;
		if (filter.name !== 'all') {
			list = list.filter(x => {
				if (filter.name === 'present') {
					return x.user.confirmed === true;
				} else if (filter.name === 'notPresent') {
					return x.user.confirmed === false;
				} else {
					return x.user.confirmed === null;
				}
			});
		}

		setFilteredList(list);
	};

	const downloadSubscriptions = async () => {
		downloadFile(
			`su_${props.entity}_${id}`,
			'application/pdf',
			`inschrijvingen_${entity.slug}.pdf`,
		);
	};

	return entitiesLoading ? (
		<Loading />
	) : (
		<div>
			<AdminHeader
				title={
					props.entity === 'news' ? 'Formulieren' : 'Inschrijvingen'
				}
				filter={
					<>
						{filterOptions
							.filter(x => x.name !== 'all')
							.map(({ name, label }, i) => (
								<button
									key={i}
									type='button'
									onClick={() => {
										handleFilter(
											filter.name === name
												? { name: 'all' }
												: { name, label },
										);
									}}
									className={`btn btn--small btn--${
										filter.name === name
											? `primary`
											: `secondary`
									}`}>
									{label} (
									{name === 'present'
										? registrations.filter(
												x => x.user.confirmed === true,
											).length
										: name === 'notPresent'
											? registrations.filter(
													x =>
														x.user.confirmed ===
														false,
												).length
											: registrations.filter(
													x =>
														x.user.confirmed ===
														null,
												).length}
									)
								</button>
							))}
					</>
				}
				optional={{
					action: downloadSubscriptions,
					label: <FontAwesomeIcon icon={faDownload} />,
				}}
			/>

			{props.entity === 'trainings' ? (
				<TrainingRegistration
					list={filteredList}
					type={props.entity}
					id={id}
				/>
			) : props.entity === 'events' ? (
				<EventRegistration
					list={filteredList}
					type={props.entity}
					id={id}
				/>
			) : (
				NewsRegistration(filteredList)
			)}
		</div>
	);
};

export default EntityRegistrations;
