import { executeAxios } from '../actions';
import { handleAlert } from '../actions/action.service';
import { errorMessageService } from '../message';

async function fetchData(filename) {
	try {
		const { data: file } = await executeAxios({
			url: `/data/${filename}`,
			headers: {
				'Content-Type': 'application/json',
			},
			responseType: 'blob',
		});
		return file;
	} catch (error) {
		handleAlert(
			'error',
			errorMessageService(
				'file',
				'download',
				error.response,
				error.response.status,
			),
		);
	}
}

function getFile(file, mime, description) {
	try {
		const blob = new Blob([file], { type: mime });
		const link = document.createElement('a');
		link.href = window.URL.createObjectURL(blob);
		link.download = description;
		link.click();
	} catch (error) {
		handleAlert(
			'error',
			errorMessageService('file', 'download', null, null),
		);
	}
}

export async function downloadFile(filename, mime, description) {
	const file = await fetchData(filename);
	if (file) {
		getFile(file, mime, description);
	}
}
