import { faRectangleXmark } from '@fortawesome/free-regular-svg-icons';
import {
	faDrumstickBite,
	faGlassCheers,
	faPeopleGroup,
} from '@fortawesome/free-solid-svg-icons';
import { INPUT_TYPES } from 'core/constants';

import {
	InputCheckbox,
	InputField,
	InputRadioButton,
	Select,
	Textarea,
	Toggle,
} from 'components/Form';

export const getFieldDetail = type => {
	switch (type) {
		case INPUT_TYPES.NUMBER:
			return {
				type: type,
				name: 'Nummer veld',
			};
		case INPUT_TYPES.SELECT:
			return {
				type: type,
				name: 'Select box',
			};
		case INPUT_TYPES.CHECKBOX:
			return {
				type: type,
				name: 'Selectievinkje',
			};
		case INPUT_TYPES.RADIO:
			return {
				type: type,
				name: 'Keuzerondjes',
			};
		case INPUT_TYPES.TEXTAREA:
			return {
				type: type,
				name: 'Tekst gebied',
			};
		case INPUT_TYPES.TOGGLE:
			return {
				type: type,
				name: 'Schakelaar',
			};
		default:
		case INPUT_TYPES.TEXT:
			return {
				type: type,
				name: 'Tekst veld',
			};
	}
};

export const getFormDetail = type => {
	switch (type) {
		case 'barbeque':
			return {
				type: type,
				name: 'UP-Barbeque',
				icon: faDrumstickBite,
			};
		case 'personeelsfeest':
			return {
				type: type,
				name: 'UP-Personeelsfeest',
				icon: faGlassCheers,
			};
		case 'food-teambuilding':
			return {
				type: type,
				name: 'Teambuilding (met eten)',
				icon: faPeopleGroup,
			};
		case 'leeg':
		default:
			return {
				type: type,
				name: 'Leeg',
				icon: faRectangleXmark,
			};
	}
};

export const getFieldInput = (type, field, settings) => {
	switch (field.type) {
		case INPUT_TYPES.SELECT:
			return (
				<Select
					id={settings.name}
					type={type}
					onChange={() => ''}
					label={settings.label}
					name={settings.name}
					description={settings.description}
					required={settings.required}
					placeholder={settings.placeholder}
					options={settings.options.map(x => ({
						label: x,
						value: x,
					}))}
				/>
			);
		case INPUT_TYPES.CHECKBOX:
			return (
				<InputCheckbox
					id={settings.name}
					type={type}
					onChange={() => ''}
					label={settings.label}
					name={settings.name}
					description={settings.description}
					required={settings.required}
					placeholder={settings.placeholder}
				/>
			);
		case INPUT_TYPES.RADIO:
			return (
				<InputRadioButton
					id={settings.name}
					type={type}
					onChange={() => ''}
					label={settings.label}
					name={settings.name}
					description={settings.description}
					required={settings.required}
					placeholder={settings.placeholder}
					options={settings.options.map(x => ({
						label: x,
						value: x,
					}))}
				/>
			);
		case INPUT_TYPES.TEXTAREA:
			return (
				<Textarea
					id={settings.name}
					type={type}
					onChange={() => ''}
					label={settings.label}
					name={settings.name}
					description={settings.description}
					required={settings.required}
					placeholder={settings.placeholder}
				/>
			);
		case INPUT_TYPES.TOGGLE:
			return (
				<Toggle
					id={settings.name}
					type={type}
					onChange={() => ''}
					label={settings.label}
					name={settings.name}
					description={settings.description}
					required={settings.required}
					placeholder={settings.placeholder}
				/>
			);
		default:
		case INPUT_TYPES.NUMBER:
		case INPUT_TYPES.TEXT:
			return (
				<InputField
					id={settings.name}
					type={type}
					onChange={() => ''}
					label={settings.label}
					name={settings.name}
					description={settings.description}
					required={settings.required}
					placeholder={settings.placeholder}
				/>
			);
	}
};

export const getFieldSettings = (type, fieldCount, field = null) => {
	const object = {
		label: field?.label ?? 'Jouw label',
		name: field?.name ?? `field-${fieldCount + 1}`,
		description: field?.description ?? '',
		placeholder: field?.placeholder ?? '',
		required: !!field?.required ?? false,
	};

	if (type === INPUT_TYPES.NUMBER) {
		object.min = field?.min ?? null;
		object.max = field?.max ?? null;
		object.step = field?.step ?? null;
	}

	if (type === INPUT_TYPES.RADIO || type === INPUT_TYPES.SELECT) {
		object.options = field?.options ?? ['opt1', 'opt2'];

		if (type === INPUT_TYPES.SELECT) {
			object.placeholder = field?.placeholder ?? 'Selecteer een optie';
		}
	}

	return object;
};

export const defaultForms = type => {
	switch (type) {
		case 'barbeque':
			return {
				formName: 'Schrijf je in voor onze barbecue',
				formSize: 'medium',
				fields: [
					{
						type: INPUT_TYPES.NUMBER,
						id: Date.now(),
						settings: {
							description: '',
							label: 'Aantal personen',
							name: 'qty',
							placeholder: '',
							min: 1,
							max: null,
							step: 1,
							required: true,
						},
					},
					{
						type: INPUT_TYPES.NUMBER,
						id: Date.now() + 1,
						settings: {
							description: '',
							label: 'Aantal vegetariërs',
							name: 'vegetarian',
							placeholder: '',
							min: 0,
							max: null,
							step: 1,
							required: false,
						},
					},
					{
						type: INPUT_TYPES.NUMBER,
						id: Date.now() + 2,
						settings: {
							description: '',
							label: 'Aantal veganisten',
							name: 'vegan',
							placeholder: '',
							min: 0,
							max: null,
							step: 1,
							required: false,
						},
					},
					{
						type: INPUT_TYPES.TEXT,
						id: Date.now() + 3,
						settings: {
							description:
								'Heb je allergieën? Laat het ons weten',
							label: 'Ik heb allergieën',
							name: 'allergies',
							placeholder: '',
							required: false,
						},
					},
				],
			};
		case 'personeelsfeest':
			return {
				formName: 'Schrijf je in voor ons personeelsfeest',
				formSize: 'medium',
				fields: [
					{
						type: INPUT_TYPES.CHECKBOX,
						id: Date.now(),
						settings: {
							description: '',
							label: 'Ik breng mijn partner mee',
							name: 'partner',
							placeholder: '',
							required: false,
						},
					},
					{
						type: INPUT_TYPES.NUMBER,
						id: Date.now() + 1,
						settings: {
							description: '',
							label: 'Aantal vegetariërs',
							name: 'vegetarian',
							placeholder: '',
							min: 0,
							max: 2,
							step: 1,
							required: false,
						},
					},
					{
						type: INPUT_TYPES.NUMBER,
						id: Date.now() + 2,
						settings: {
							description: '',
							label: 'Aantal veganisten',
							name: 'vegan',
							placeholder: '',
							min: 0,
							max: 2,
							step: 1,
							required: false,
						},
					},
					{
						type: INPUT_TYPES.TEXT,
						id: Date.now() + 3,
						settings: {
							description:
								'Heb je allergieën? Laat het ons weten',
							label: 'Ik heb allergieën',
							name: 'allergies',
							placeholder: '',
							required: false,
						},
					},
				],
			};
		case 'food-teambuilding':
			return {
				formName: 'Schrijf je in voor onze teambuilding',
				formSize: 'medium',
				fields: [
					{
						type: INPUT_TYPES.CHECKBOX,
						id: Date.now(),
						settings: {
							description: '',
							label: 'Ik ben vegetarisch',
							name: 'vegetarian',
							placeholder: '',
							required: false,
						},
					},
					{
						type: INPUT_TYPES.CHECKBOX,
						id: Date.now() + 1,
						settings: {
							description: '',
							label: 'Ik ben veganistisch',
							name: 'vegan',
							placeholder: '',
							required: false,
						},
					},
					{
						type: INPUT_TYPES.TEXT,
						id: Date.now() + 2,
						settings: {
							description:
								'Heb je allergieën? Laat het ons weten',
							label: 'Ik heb allergieën',
							name: 'allergies',
							placeholder: '',
							required: false,
						},
					},
				],
			};
		case 'empty':
		default:
			return {
				formName: 'Vul het formulier in',
				formSize: 'medium',
				fields: [],
			};
	}
};

export const createBuilderFromForm = form => {
	if (form && Object.keys(form).length) {
		return {
			formName: form.formName,
			formSize: form.formSize,
			fields: form.format.fields.map((fieldName, i) => {
				const field = form.fields.find(
					field => field.name === fieldName,
				);

				return {
					type: field.type,
					id: field.id,
					settings: getFieldSettings(field.type, i, field),
				};
			}),
		};
	}

	return {
		formName: 'Vul het formulier in',
		formSize: 'medium',
		fields: [],
	};
};

export const createFormFromBuilder = builder => {
	if (builder?.fields.length) {
		return {
			formName: builder.formName,
			formSize: builder.formSize,
			fields: builder.fields.map(x => ({
				type: x.type,
				...x.settings,
			})),
			format: {
				direction: 'vertical',
				label: null,
				name: 'group1',
				type: 'group',
				fields: builder.fields.map(x => x.settings.name),
			},
		};
	}

	return null;
};
