import React from 'react';
import { Link } from 'react-router-dom';

import { Button } from 'components/Form';

import './AdminHeader.scss';

export const AdminHeader = ({ title, optional = null, filter = null }) => {
	const renderLink = (url, label) => (
		<Link to={url} className='btn btn--primary'>
			{label}
		</Link>
	);

	const renderButton = (action, label) => (
		<Button buttonStyle='primary' label={label} onClick={action} />
	);

	return (
		<>
			<section className='admin-header'>
				<h1>{title}</h1>

				{optional ? (
					<section
						className='actions btn__group'
						style={{ margin: 0 }}>
						{Array.isArray(optional)
							? optional.map(x =>
									x.url !== undefined
										? renderLink(x.url, x.label)
										: renderButton(x.action, x.label),
								)
							: typeof optional === 'object'
								? optional.url !== undefined
									? renderLink(optional.url, optional.label)
									: renderButton(
											optional.action,
											optional.label,
										)
								: null}
					</section>
				) : null}
			</section>
			{filter ? (
				<section className='filter-container'>{filter}</section>
			) : null}
		</>
	);
};
