export const createSelectGroup = (name, options, status, setSelect) => {
	return {
		label: (() => (
			<div
				className='select__label'
				onClick={() =>
					setSelect(value => [
						...value,
						...options
							.filter(grOpt => !value.includes(grOpt.value))
							.map(x => x.value),
					])
				}>
				{name} ({options.length})
			</div>
		))(),
		options: options.map(x => ({
			value: x.value,
			label: (
				<div className='select__status'>
					<div className={`select__status--indicator ${status}`} />
					{x.label}
				</div>
			),
		})),
	};
};
