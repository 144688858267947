import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import {
	faChampagneGlasses,
	faGraduationCap,
	faListCheck,
	faPaperPlane,
	faPen,
	faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getEntityTypeDetails } from 'core/helpers/entities/entity.helper';
import { loadEntity } from 'core/store/entities/entity.actions';

import Card from 'components/Card/Card';
import { Col, Row } from 'components/Grid';
import Loading from 'components/Loading/Loading';

import './MailBox.scss';

const MailBox = () => {
	const dispatch = useDispatch();
	const { state } = useLocation();

	const { entitiesLoading } = useSelector(state => state.entityReducer);

	useEffect(() => {
		if (state?.type && state?.id)
			dispatch(loadEntity(state?.type, state?.id));
	}, [dispatch]);

	return (
		<section className='mailbox'>
			{state?.type && state?.id ? (
				entitiesLoading ? (
					<Loading />
				) : (
					<Card withShadow>
						<Row>
							<Col
								lg={2}
								md={3}
								sm={4}
								className='mailbox__navigation'>
								<ul>
									<li>
										<NavLink
											to='/mailbox/nieuw'
											state={state}>
											<FontAwesomeIcon icon={faPen} />
											Nieuw
										</NavLink>
									</li>
									<li>
										<NavLink
											to='/mailbox/verzonden'
											state={state}>
											<FontAwesomeIcon
												icon={faPaperPlane}
											/>
											Verzonden
										</NavLink>
									</li>
								</ul>
								<ul>
									<li>
										<NavLink
											to={`/${getEntityTypeDetails(state?.type).name.toLowerCase()}`}
											state={state}>
											<FontAwesomeIcon
												icon={
													state?.type === 'events'
														? faChampagneGlasses
														: faGraduationCap
												}
											/>
											Overzicht
										</NavLink>
									</li>
									<li>
										<NavLink
											to={`/${getEntityTypeDetails(state?.type).name.toLowerCase()}/${state?.id}`}
											state={state}>
											<FontAwesomeIcon icon={faEdit} />
											Detail
										</NavLink>
									</li>
									<li>
										<NavLink
											to={`/${getEntityTypeDetails(state?.type).name.toLowerCase()}/${state?.id}/registraties`}
											state={state}>
											<FontAwesomeIcon
												icon={faListCheck}
											/>
											Inschrijvingen
										</NavLink>
									</li>
									<li>
										<NavLink
											to={`/${getEntityTypeDetails(state?.type).name.toLowerCase()}/${state?.id}/aanwezigheden`}
											state={state}>
											<FontAwesomeIcon icon={faUsers} />
											Aanwezigheden
										</NavLink>
									</li>
								</ul>
							</Col>
							<Col
								lg={10}
								md={9}
								sm={8}
								className='mailbox__content'>
								<Outlet context={state} />
							</Col>
						</Row>
					</Card>
				)
			) : (
				<div className='empty-list'>
					Geen evenement of opleiding geselecteerd
				</div>
			)}
		</section>
	);
};

export default MailBox;
