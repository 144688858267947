import React from 'react';
import { Link } from 'react-router-dom';

import Card from 'components/Card/Card';
import DataTable from 'components/DataTable/DataTable';
import Pill from 'components/Pill/Pill';

const SubscriptionTable = ({ title, id, list, setSelected, permissions }) => {
	return (
		<Card
			title='Inschrijvingen'
			footer={
				<div className='btn__group full--flex' style={{ width: '50%' }}>
					<Link
						className='btn btn--secondary btn--small'
						to={`/${title}`}>
						Overzicht
					</Link>
					<Link
						className='btn btn--secondary btn--small'
						to={`/${title}/${id}`}>
						Detail
					</Link>
					<Link
						className='btn btn--secondary btn--small'
						to={`/${title}/${id}/aanwezigheden`}>
						Aanwezigheden
					</Link>
				</div>
			}
			withShadow>
			<DataTable
				data={list.map(x => ({
					...x.user,
					confirmed: (
						<Pill
							type={
								x.user.confirmed === null
									? 'warning'
									: x.user.confirmed
										? 'success'
										: 'danger'
							}
							text={
								x.user.confirmed === null
									? 'ONBEVESTIGD'
									: x.user.confirmed
										? 'AANWEZIG'
										: 'AFWEZIG'
							}
							block
						/>
					),
				}))}
				columns={[
					{
						name: 'firstName',
						sort: true,
						label: 'Voornaam',
					},
					{
						name: 'lastName',
						sort: true,
						label: 'Naam',
					},
					{
						name: 'email',
						sort: true,
						label: 'E-mail',
					},
					{
						name: 'confirmed',
						sort: true,
						label: 'Status',
						className: 'center',
						style: { width: '150px' },
					},
					{
						name: 'actions',
						label: '',
						sort: false,
						actions: {
							update: (key, id) =>
								setSelected(list.find(x => x.user.id === id)),
						},
						className: 'right',
						disabled: permissions.includes('forms_update')
							? () => false
							: (key, id) =>
									!list.find(x => x.user.id === id)?.user
										.confirmed,
					},
				]}
				search
				paginate
			/>
		</Card>
	);
};

export default SubscriptionTable;
