import React from 'react';

import { Button } from 'components/Form';

const Selector = ({ selected, setSelected }) => {
	return (
		<div className='btn__group full--flex' style={{ marginBottom: '5rem' }}>
			<Button
				buttonStyle={
					selected?.user.confirmed === true ||
					selected?.user.confirmed === null
						? 'primary'
						: 'secondary'
				}
				label='Aanwezig'
				className='success'
				type='button'
				onClick={() =>
					setSelected(prevState => ({
						...prevState,
						user: {
							...prevState.user,
							confirmed: true,
						},
					}))
				}
			/>
			<Button
				buttonStyle={
					selected?.user.confirmed === false ||
					selected?.user.confirmed === null
						? 'primary'
						: 'secondary'
				}
				label='Afwezig'
				className='danger'
				type='button'
				onClick={() =>
					setSelected(prevState => ({
						...prevState,
						user: {
							...prevState.user,
							confirmed: false,
						},
					}))
				}
			/>
		</div>
	);
};

export default Selector;
