import { executeAxios } from 'core/services/actions';
import { handleAlert } from 'core/services/actions/action.service';

export const updateAttachment = async (id, body) => {
	try {
		const { data } = await executeAxios({
			url: `/admin/attachments/${id}`,
			data: body,
			method: 'PUT',
		});

		return data.data;
	} catch (error) {
		handleAlert('error', 'Er is iets misgegaan');
		return null;
	}
};

export const removeAttachment = async id => {
	try {
		const { data } = await executeAxios({
			url: `/admin/attachments/${id}`,
			method: 'DELETE',
		});

		return data.data;
	} catch (error) {
		handleAlert('error', 'Er is iets misgegaan');
		return null;
	}
};
