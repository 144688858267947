import { INPUT_TYPES } from 'core/constants';
import { isRequired, isRequiredHtml } from 'core/services/form';

import { getEntityTypeDetails } from '../entities/entity.helper';

import { createSelectGroup } from './mailbox.helper';

export const mailboxFormData = (type, entity, setSelect) => {
	return {
		entity: {
			type: INPUT_TYPES.TEXT,
			name: 'entity',
			label: getEntityTypeDetails(type).single,
			value: entity?.name,
			disabled: true,
			required: true,
			validations: [],
		},
		to: {
			type: INPUT_TYPES.SELECT,
			name: 'to',
			label: 'Aan',
			options: [
				createSelectGroup(
					'Aanwezig',
					entity?.invitees
						?.filter(x => x.confirmed === 1)
						?.map(x => ({
							label: `${x.firstName} ${x.lastName}`,
							value: x.id,
						})) ?? [],
					'success',
					setSelect,
				),
				createSelectGroup(
					'Niet aanwezig',
					entity?.invitees
						?.filter(x => x.confirmed === 0)
						?.map(x => ({
							label: `${x.firstName} ${x.lastName}`,
							value: x.id,
						})) ?? [],
					'danger',
					setSelect,
				),
				createSelectGroup(
					'Onbevestigd',
					entity?.invitees
						?.filter(x => x.confirmed === null)
						?.map(x => ({
							label: `${x.firstName} ${x.lastName}`,
							value: x.id,
						})) ?? [],
					'warning',
					setSelect,
				),
			],
			isMulti: true,
			isClearable: true,
			required: true,
			placeholder: 'Selecteer de ontvangers',
			description:
				'Klik op het label om een volledige groep te selecteren',
			validations: [isRequired],
		},
		title: {
			type: INPUT_TYPES.TEXT,
			name: 'title',
			label: 'Titel',
			value: `Extra info: ${entity?.name}`,
			required: true,
			validations: [isRequired],
		},
		content: {
			type: INPUT_TYPES.TINY,
			name: 'content',
			label: 'Inhoud',
			required: true,
			placeholder:
				'Schrijf je bericht.\nEen persoonlijk aanhef wordt automatisch gegenereerd.',
			validations: [isRequiredHtml],
		},
	};
};
