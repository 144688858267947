import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { mailboxFormData } from 'core/helpers/mailBox/mailbox.form-data';
import { useForm } from 'core/hooks/form/useForm';
import { useSubmit } from 'core/hooks/submit/useSubmit';
import { sendMailbox } from 'core/store/mailbox/mailbox.actions';

import { Button, InputField, Select, TinyEditor } from 'components/Form';

const New = () => {
	const navigate = useNavigate();
	const context = useOutletContext();

	const { handleSubmit } = useSubmit();
	const { register, setValue, getValues } = useForm();

	const [select, setSelect] = useState([]);

	const { entity } = useSelector(state => state.entityReducer);

	useEffect(() => {
		setValue('to', select);
	}, [select]);

	const form = mailboxFormData(context?.type, entity, setSelect);

	const submitForm = async () => {
		// eslint-disable-next-line no-unused-vars
		const { entity, ...values } = getValues();

		await handleSubmit(
			sendMailbox(values, context?.type, context?.id, navigate),
		);
	};

	return (
		<section>
			<InputField {...register('entity', form.entity)} />
			<Select {...register('to', form.to)} />
			<InputField {...register('title', form.title)} />
			<TinyEditor {...register('content', form.content)} />

			<Button
				buttonStyle='primary'
				label='Verzenden'
				onClick={submitForm}
			/>
		</section>
	);
};

export default New;
