import { ACTION_TYPES } from 'core/constants/action.constants/action.constants';

let defaultState = {
	entities: [],
	entity: null,
	form: null,
	currentPage: 1,
	lastPage: 1,
	fromItem: 1,
	toItem: 1,
	totalItems: 1,
	registrations: [],
	entitiesLoading: false,
};

const loadEntities = (state, { entitiesLoading, currentPage }) => ({
	...state,
	entitiesLoading,
	currentPage,
	entity: null,
	form: null,
	registrations: [],
});

const loadEntitiesCommit = (state, { data, lastPage, from, to, total }) => ({
	...state,
	entities: data,
	lastPage: lastPage,
	fromItem: from,
	toItem: to,
	totalItems: total,
	entitiesLoading: false,
});

const updateEntities = (state, payload) => ({
	...state,
	entities: state.entities.map(x => {
		if (x.id !== payload.id) return x;
		return {
			...x,
			...payload,
		};
	}),
});

const loadEntity = (state, { entitiesLoading, entity }) => ({
	...state,
	entitiesLoading,
	entity,
});

const loadEntityCommit = (state, payload) => ({
	...state,
	entity: payload,
	entitiesLoading: false,
});

const deleteEntity = (state, { id }) => ({
	...state,
	entities: state.entities.filter(item => item.id !== id),
});

const loadRegistrations = (state, { entitiesLoading }) => ({
	...state,
	entitiesLoading,
});

const loadRegistrationsCommit = (state, payload) => ({
	...state,
	registrations: payload,
	entitiesLoading: false,
});

const loadForm = (state, { form }) => ({
	...state,
	form,
});

const loadFormCommit = (state, payload) => ({
	...state,
	form: payload,
});

const saveForm = (state, { userId, data: { data } }) => ({
	...state,
	registrations: state.registrations.map(registration => {
		if (registration.user.id !== userId) return registration;

		return {
			...registration,
			submissions:
				typeof registration === 'object'
					? {
							order: data.results,
							remark: data.remark,
						}
					: registration.submissions.map(submission => {
							const value = data.results.find(
								result => result.name === submission.field.name,
							);

							return {
								...submission,
								value:
									submission.field.type === 'checkbox'
										? value.values
											? '1'
											: '0'
										: value.values
											? value.values
											: '',
							};
						}),
		};
	}),
});

const saveSubscription = (state, { userId, data: { data } }) => ({
	...state,
	registrations: state.registrations.map(x => {
		if (x.user.id !== userId) return x;

		return {
			...x,
			user: {
				...x.user,
				...data,
			},
		};
	}),
});

const saveAttendancy = (state, { data: { data }, userId }) => ({
	...state,
	entity: {
		...state.entity,
		invitees: state.entity.invitees.map(x => {
			if (x.id !== userId) return x;

			return {
				...x,
				presences: data,
			};
		}),
	},
});

export const entityReducer = (state = defaultState, { type, payload }) => {
	switch (type) {
		case ACTION_TYPES.ENTITIES_LOAD:
			return loadEntities(state, payload);
		case ACTION_TYPES.ENTITIES_LOAD_COMMIT:
			return loadEntitiesCommit(state, payload.data);
		case ACTION_TYPES.ENTITY_PUT_COMMIT:
			return updateEntities(state, payload.data);
		case ACTION_TYPES.ENTITY_LOAD:
			return loadEntity(state, payload);
		case ACTION_TYPES.ENTITY_LOAD_COMMIT:
			return loadEntityCommit(state, payload.data);
		case ACTION_TYPES.ENTITY_DELETE_COMMIT:
			return deleteEntity(state, payload);
		case ACTION_TYPES.ENTITY_LOAD_REGISTRATIONS:
			return loadRegistrations(state, payload);
		case ACTION_TYPES.ENTITY_LOAD_REGISTRATIONS_COMMIT:
			return loadRegistrationsCommit(state, payload.data);
		case ACTION_TYPES.ENTITY_FORM_LOAD:
			return loadForm(state, payload);
		case ACTION_TYPES.ENTITY_FORM_LOAD_COMMIT:
			return loadFormCommit(state, payload.data);
		case ACTION_TYPES.ENTITY_POST_REGISTRATIONS_COMMIT:
			return saveForm(state, payload);
		case ACTION_TYPES.ENTITY_POST_SUBSCRIPTION_COMMIT:
			return saveSubscription(state, payload);
		case ACTION_TYPES.ENTITY_PUT_ATTENDANCY_COMMIT:
			return saveAttendancy(state, payload);
		default:
			return state;
	}
};
