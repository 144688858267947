export const toShortDate = (inputDate, hideYear = false) => {
	let options = {};
	const timestamp = new Date(inputDate).getTime() / 1000; // Convert inputDate to a valid timestamp
	if (hideYear) options = { month: '2-digit', day: 'numeric' };
	else options = { year: 'numeric', month: '2-digit', day: 'numeric' };
	return new Date(timestamp * 1000).toLocaleDateString(`nl-NL`, options);
};

export const toLongDate = inputDate => {
	const options = {
		weekday: 'short',
		year: 'numeric',
		month: 'short',
		day: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
	};
	return new Date(inputDate).toLocaleString(`nl-NL`, options);
};

export const getDate = (datetime, toTime = false) => {
	const timestamp = new Date(datetime);
	if (toTime) {
		return `${timestamp.getHours().toString().padStart(2, '0')}:${timestamp.getMinutes().toString().padStart(2, '0')}`;
	}

	return toShortDate(timestamp);
};

export const isPast = date => {
	const now = new Date();
	const newDate = new Date(date);
	return newDate.getTime() < now.getTime();
};

export const manipulateDate = (date, days, add = true) => {
	const result = date
		? typeof date === 'string'
			? new Date(date)
			: date
		: new Date();

	if (add) {
		result.setDate(result.getDate() + days);
	} else {
		result.setDate(result.getDate() - days);
	}

	return result;
};

export const toDateString = date => {
	const newDate = date
		? typeof date === 'string'
			? new Date(date)
			: date
		: new Date();
	newDate.setTime(newDate.getTime() - newDate.getTimezoneOffset() * 60000);
	return newDate.toISOString().slice(0, 10);
};
