import { INPUT_TYPES } from 'core/constants';
import { getDate } from 'core/services/date';
import { isRequired } from 'core/services/form';

export const wellBeingFormData = object => {
	return {
		name: {
			type: INPUT_TYPES.TEXT,
			name: 'name',
			label: 'Naam',
			required: true,
			validations: [isRequired],
			value: object?.name ?? '',
		},
		start_date: {
			type: INPUT_TYPES.DATEINPUT,
			name: 'start_date',
			label: 'Startdatum',
			description:
				'Selecteer de datum vanaf wanneer de barometer actief moet worden',
			value:
				object && object?.startDate ? getDate(object?.startDate) : '',
			required: true,
			validations: [isRequired],
			disabledDays: [{ before: new Date() }],
		},
		end_date: {
			type: INPUT_TYPES.DATEINPUT,
			name: 'end_date',
			label: 'einddatum',
			description:
				'Selecteer de datum tot wanneer de barometer actief moet blijven',
			value: object && object?.endDate ? getDate(object?.endDate) : '',
			required: true,
			validations: [isRequired],
			disabledDays: [{ before: new Date() }],
		},
		questions: {
			type: INPUT_TYPES.HIDDEN,
			name: 'questions',
			value: object?.questions ?? [],
			required: true,
			validations: [isRequired],
		},
	};
};

export const wellBeingQuestionFormData = (question = null) => {
	return {
		question: {
			type: INPUT_TYPES.TEXT,
			name: 'question',
			label: 'Vraag',
			required: true,
			validations: [isRequired],
			value: question?.question ?? '',
		},
		type: {
			type: INPUT_TYPES.SELECT,
			name: 'type',
			label: 'Type',
			placeholder: 'Selecteer het type',
			required: true,
			validations: [isRequired],
			options: [
				{ label: 'Meerkeuzevraag', value: 'M' },
				{ label: 'Smilies', value: 'S' },
			],
			isClearable: false,
			value: question?.type ?? '',
		},
		options: {
			type: INPUT_TYPES.HIDDEN,
			name: 'options',
			validations: [],
			value: question?.options ?? [],
		},
	};
};

export const wellBeingOptionFormData = () => {
	return [
		{
			type: INPUT_TYPES.TEXT,
			name: 'option',
			label: 'Optie',
			required: true,
			validations: [isRequired],
			value: '',
		},
		{
			type: INPUT_TYPES.CHECKBOX,
			name: 'triggerEmail',
			label: 'E-mail triggeren',
			value: false,
		},
	];
};
