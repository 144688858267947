import { ACTION_TYPES } from 'core/constants/action.constants/action.constants';

/**
 * @param {string} type
 * @param {object} error
 * @returns {string}
 */
export const requestMessageService = (type, error) => {
	console.group('%cerror', 'color: red');
	console.error(type, error);
	console.groupEnd();
	switch (type) {
		case ACTION_TYPES.THEME_MODE_UPDATE:
		case ACTION_TYPES.THEME_UPDATE:
			return errorMessageService(
				'theme',
				'update',
				error.response,
				error.response.status,
			);

		default:
			return 'Er ging its onverwachts fout. Neem contact op met support@unipartners.be wanneer het probleem zich blijft voordoen.';
	}
};

/**
 * @param {string} type
 * @param {string} action
 * @param {object} response
 * @param {int} status
 * @returns {string}
 */
export const errorMessageService = (type, action, response, status) => {
	if (response) {
		if (status && status === 401) {
			return `Je bent niet bevoegd om ${typeHelper(
				type,
				true,
			).toLowerCase()} ${actionHelper(action)}.`;
		}
		if (status && status === 403) {
			return `Je hebt niet voldoende rechten om ${typeHelper(
				type,
				true,
			).toLowerCase()} ${actionHelper(action)}.`;
		}
		if (status && status === 404) {
			return `${typeHelper(type)} niet gevonden.`;
		}
	}

	return `${typeHelper(type)} ${actionHelper(
		action,
		false,
	)} mislukt vanwege een onverwachte fout.`;
};

/**
 * @param {string} type
 * @param {string} action
 * @returns {`${string} succesvol ${string}.`}
 */
export const successMessageService = (type, action) => {
	return `${typeHelper(type)} succesvol ${actionHelper(action)}.`;
};

/**
 * @param {string} type The type of item item
 * @param {boolean} addArticle Add the article
 * @returns {string}
 */
const typeHelper = (type, addArticle = false) => {
	switch (type) {
		case 'image':
			return `${addArticle ? 'De ' : ''}Afbeelding`;
		case 'file':
			return `${addArticle ? 'Het ' : ''}Bestand`;
		case 'theme':
			return `${addArticle ? 'Het ' : ''}Thema`;
		case 'articles':
			return `${addArticle ? 'De ' : ''}Artikelen`;
		case 'article':
		case 'nieuws':
		case 'news':
			return `${addArticle ? 'Het ' : ''}Artikel`;
		case 'well-being':
			return `${addArticle ? 'De ' : ''}Barometer`;
		case 'moving-mission':
			return `${addArticle ? 'De ' : ''}Missie`;
		case 'permission':
			return `${addArticle ? 'De ' : ''}Permissie`;
		case 'permissions':
			return `${addArticle ? 'De ' : ''}Permissies`;
		case 'role':
			return `${addArticle ? 'De ' : ''}Rol`;
		case 'roles':
			return `${addArticle ? 'De ' : ''}Rollen`;
		case 'newsletter':
			return `${addArticle ? 'De ' : ''}Nieuwsbrief`;
		case 'instructor':
			return `${addArticle ? 'De ' : ''}Lesgever`;
		case 'subscription':
			return `${addArticle ? 'Het ' : ''}Formulier`;
		case 'attendance':
			return `${addArticle ? 'De ' : ''}Aanwezigheid`;
		default:
			return `dit`;
	}
};

/**
 * @param {string} action Action to be described
 * @param {boolean} addPreposition Add a preposition to the return
 * @returns {string|string}
 */
const actionHelper = (action, addPreposition = true) => {
	switch (action) {
		case 'save':
			return `${addPreposition ? 'te ' : ''}bewaren`;
		case 'saved':
			return 'bewaard';
		case 'load':
			return `${addPreposition ? 'te ' : ''}laden`;
		case 'loaded':
			return 'geladen';
		case 'create':
			return `aan${addPreposition ? ' te ' : ''}maken`;
		case 'created':
			return 'aangemaakt';
		case 'read':
			return `${addPreposition ? 'te ' : ''}bekijken`;
		case 'update':
			return `${addPreposition ? 'te ' : ''}wijzigen`;
		case 'updated':
			return 'gewijzigd';
		case 'delete':
			return `${addPreposition ? 'te ' : ''}verwijderen`;
		case 'deleted':
			return 'verwijderd';
		case 'publish':
			return `${addPreposition ? 'te ' : ''}publiceren`;
		case 'published':
			return 'gepubliceerd';
		case 'pin':
			return `vast${addPreposition ? ' te ' : ''}pinnen`;
		case 'pinned':
			return 'vastgepind';
		case 'download':
			return `${addPreposition ? ' te ' : ''}downloaden`;
		case 'downloaded':
			return 'gedownload';
		case 'unpin':
			return `los${addPreposition ? ' te ' : ''}maken`;
		case 'unpinned':
			return 'los gemaakt';
		case 'sent':
			return `verzonden`;
		case 'reset':
			return `hersteld`;
		default:
			return addPreposition ? 'te doen' : 'laden';
	}
};
