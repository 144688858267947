import { ACTION_TYPES } from 'core/constants/action.constants/action.constants';

let defaultState = {
	mailboxes: [],
	mailboxesLoading: false,
};

const loadMailboxes = (state, { mailboxesLoading }) => ({
	...state,
	mailboxesLoading,
});

const loadMailboxesCommit = (state, payload) => ({
	...state,
	mailboxes: payload,
	mailboxesLoading: false,
});

export const mailboxReducer = (state = defaultState, { type, payload }) => {
	switch (type) {
		case ACTION_TYPES.MAILBOX_LOAD:
			return loadMailboxes(state, payload);
		case ACTION_TYPES.MAILBOX_LOAD_COMMIT:
			return loadMailboxesCommit(state, payload.data);
		default:
			return state;
	}
};
