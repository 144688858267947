import { INPUT_TYPES } from 'core/constants';
import { isRequired } from 'core/services/form';

export const formBuilderFormData = object => {
	return [
		{
			type: INPUT_TYPES.TEXT,
			name: 'formName',
			label: 'Naam',
			description: 'De naam wordt boven het formulier weergegeven',
			required: true,
			validations: [isRequired],
			value: object?.formName ?? '',
		},
		{
			type: INPUT_TYPES.SELECT,
			name: 'formSize',
			label: 'Formaat',
			placeholder: 'Selecteer het formaat van het formulier',
			options: [
				{ label: 'Klein', value: 'small' },
				{ label: 'Medium', value: 'medium' },
				{ label: 'Groot', value: 'large' },
			],
			required: true,
			validations: [isRequired],
			value: object?.formSize,
		},
	];
};

export const formBuilderCustomFormData = object => {
	console.log(object);
	return [
		{
			type: INPUT_TYPES.HIDDEN,
			name: 'id',
			value: object?.id,
		},
		{
			type: INPUT_TYPES.TEXT,
			name: 'name',
			label: 'Naam',
			description:
				'Gebruik geen spaties of leestekens in de naam. Enkel streepjes zijn wel toegestaan.',
			required: true,
			validations: [isRequired],
			value: object?.name ?? '',
		},
		{
			type: INPUT_TYPES.TEXT,
			name: 'label',
			label: 'Label',
			required: true,
			validations: [isRequired],
			value: object?.label ?? '',
		},
		{
			type: INPUT_TYPES.TEXT,
			name: 'description',
			label: 'Beschrijving',
			description: 'Hou de beschrijving kort, maar duidelijk.',
			validations: [],
			value: object?.description ?? '',
		},
		{
			type: INPUT_TYPES.TEXT,
			name: 'placeholder',
			label: 'Placeholder',
			validations: [],
			value: object?.placeholder ?? '',
		},
		...(object?.type === INPUT_TYPES.SELECT ||
		object?.type === INPUT_TYPES.RADIO
			? [
					{
						type: INPUT_TYPES.CREATABLE,
						name: 'options',
						label: 'Opties',
						description:
							'Voeg een optie toe door ze in te typen en op enter te drukken.',
						placeholder: 'Selecteer je opties',
						options: object?.options.map(x => ({
							label: x,
							value: x,
						})),
						isCreatable: true,
						isMulti: true,
						isClearable: true,
						validations: [],
						value: object?.options,
					},
				]
			: []),
		...(object?.type === INPUT_TYPES.NUMBER
			? [
					{
						type: INPUT_TYPES.NUMBER,
						name: 'min',
						label: 'Minimum waarde',
						validations: [],
						value: object?.min,
					},
					{
						type: INPUT_TYPES.NUMBER,
						name: 'max',
						label: 'Maximum waarde',
						validations: [],
						value: object?.max,
					},
					{
						type: INPUT_TYPES.NUMBER,
						name: 'step',
						label: 'Step waarde',
						description:
							'De waarde van het veld kan met deze waarde verhogen of verlagen.',
						validations: [],
						value: object?.step,
					},
				]
			: []),
		{
			type: INPUT_TYPES.CHECKBOX,
			name: 'required',
			label: 'Verplicht',
			description: 'Is het veld verplicht in te vullen?',
			validations: [],
			value: object?.required ?? '',
		},
	];
};
