import React, { Component } from 'react';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import background from 'assets/images/broken.png';
import { db } from 'dexie-db';

import { Col, Container, Row } from 'components/Grid';

import './ErrorBoundry.scss';

class ErrorBoundary extends Component {
	constructor(props) {
		super(props);

		this.state = {
			hasError: false,
			endTime: null,
			interval: 5,
		};
	}
	//todo fix this refresh
	// eslint-disable-next-line react/no-unused-class-component-methods
	refresh = () => {
		window.location.reload();
	};

	showInterval = () => {
		this.setState({
			interval: `${Math.ceil(
				// eslint-disable-next-line react/no-access-state-in-setstate
				(this.state.endTime - new Date().getTime()) / 1000,
			)}`,
		});
	};

	static getDerivedStateFromError() {
		return { hasError: true };
	}

	componentDidCatch() {
		db.delete();
		localStorage.removeItem('rememberMe');

		this.setState({ endTime: new Date().getTime() + 6000 });
		setTimeout(() => {
			//TODO: set refresh
			//this.refresh();
		}, 6000);

		setInterval(() => {
			this.showInterval();
		}, 1000);
	}

	render() {
		const { hasError, interval } = this.state;

		if (hasError) {
			// You can render any custom fallback UI
			return (
				<div
					className={`error-wrapper theme--${
						window.matchMedia('(prefers-color-scheme: dark)')
							.matches
							? 'dark'
							: 'light'
					}`}
					style={{ backgroundImage: `url(${background})` }}>
					<Container>
						<Row>
							<Col lg={4} md={6} sm={6} xs={12}>
								<h4>Whoops! Wat deed je nu!?</h4>
								<h1>500</h1>
								<p>
									We hebben een technisch probleem.
									{interval >= 0
										? ` We proberen de pagina te herladen binnen ${interval} seconden.`
										: ' We konden de pagina niet herladen. Klik op onderstaande knop om opnieuw te proberen.'}
								</p>
								<p>
									Blijft het probleem zich voordoen? Neem
									contact op met{' '}
									<a href='mailto: support@unipartners.be'>
										support@unipartners.be
									</a>
									.
								</p>

								<a
									href='/'
									className='btn btn--secondary btn--block'>
									<FontAwesomeIcon icon={faHome} />
									<span>Terug naar de homepage</span>
								</a>
							</Col>
						</Row>
					</Container>
				</div>
			);
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
