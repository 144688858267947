import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isPast, toShortDate } from 'core/services/date';
import {
	deleteEntity,
	loadEntities,
	putEntity,
} from 'core/store/entities/entity.actions';

import { Confirmation } from 'components/Confirmation/Confirmation';
import { AdminHeader } from 'components/Layouts/AdminLayout/AdminHeader/AdminHeader';
import { ListItem } from 'components/ListItem/ListItem';
import Loading from 'components/Loading/Loading';
import { Pagination } from 'components/Pagination/Pagination';

import './EntityList.scss';

const EntityList = ({
	entity: { type, name, title },
	filterOptions,
	permissions,
}) => {
	const dispatch = useDispatch();

	const {
		entitiesLoading,
		entities,
		currentPage,
		lastPage,
		fromItem,
		toItem,
		totalItems,
	} = useSelector(state => state.entityReducer);

	const [filter, setFilter] = useState(filterOptions[0]);
	const [modal, setModal] = useState({ action: null, id: null });

	useEffect(() => {
		dispatch(loadEntities(type, currentPage, filter));
	}, [type, dispatch]);

	const handlePagination = ({ selected }) => {
		dispatch(loadEntities(type, selected + 1, filter));
	};

	const handleFilter = filter => {
		setFilter(filter);
		dispatch(loadEntities(type, currentPage, filter));
	};

	const handleClick = (action, id) => {
		if (action === 'delete') {
			dispatch(deleteEntity(type, id));
		} else {
			dispatch(putEntity(type, action, id));
		}
	};

	return (
		<section>
			<AdminHeader
				title={title}
				optional={
					permissions.includes(`${type}_add`)
						? {
								url: 'toevoegen',
								label: `+ Toevoegen`,
							}
						: null
				}
				filter={
					<>
						{filterOptions
							.filter(x => x.name !== 'all')
							.map(({ name, label }, i) => (
								<button
									key={i}
									type='button'
									onClick={() => {
										handleFilter(
											filter.name === name
												? { name: 'all' }
												: { name, label },
										);
									}}
									className={`btn btn--small btn--${
										filter.name === name
											? `primary`
											: `secondary`
									}`}>
									{label}
								</button>
							))}
					</>
				}
			/>

			{!entitiesLoading ? (
				entities.length ? (
					entities.map(article => (
						<ListItem
							key={article.id}
							id={article.id}
							title={article.title ?? article.name}
							slug={
								permissions.includes(`${type}_update`)
									? `/${name.toLowerCase()}/${article.id}`
									: null
							}
							pin={
								type === 'news' &&
								permissions.includes(`news_update`)
									? () => handleClick('pin', article.id)
									: null
							}
							showEmail={
								permissions.includes(`${type}_mailbox`) &&
								permissions.includes('see_mailbox')
									? article.hasInvitations
									: false
							}
							important={
								type === 'news' &&
								permissions.includes(`news_update`)
									? () => handleClick('highlight', article.id)
									: null
							}
							showDeleteIcon={permissions.includes(
								`${type}_delete`,
							)}
							showPublishIcon={permissions.includes(
								`${type}_publish`,
							)}
							showAttendancy={
								permissions.includes(`${type}_see_results`)
									? type !== 'news'
									: false
							}
							showResultsIcon={
								permissions.includes(`${type}_see_results`)
									? article.hasInvitations || article.hasForm
									: false
							}
							type={type}
							publishedAt={article.publishedAt}
							isImportant={article.important}
							isPinned={article.pinned}
							setModal={setModal}
							className={`entity ${article.publishedAt ? (isPast(article.publishedAt) ? 'published' : 'unpublished') : ''}`}>
							<i>
								<b>Gepubliceerd op: </b>
								{article.publishedAt
									? toShortDate(article.publishedAt)
									: 'niet gepubliceerd'}
							</i>
						</ListItem>
					))
				) : (
					<div className='empty-list'>
						{filter.name === 'all'
							? 'Geen data om weer te geven'
							: 'Er zijn geen items die voldoen aan je selectie'}
					</div>
				)
			) : (
				<Loading />
			)}

			{filter.name === 'all' ? (
				<Pagination
					from={fromItem}
					to={toItem}
					total={totalItems}
					pageCount={lastPage}
					paginate={handlePagination}
				/>
			) : null}

			<Confirmation
				show={!!modal.action}
				close={() => setModal({ action: null, id: null })}
				action={() => handleClick(modal.action, modal.id)}
				title='Weet je het zeker?'>
				{modal.action === 'delete'
					? 'Weet je zeker dat je dit item wil verwijderen?'
					: `Weet je zeker dat je ${entities.find(x => x.id === modal?.id)?.publishedAt ? 'de publicatie van dit item wil ongedaan maken?' : 'dit item wil publiceren?'}`}
			</Confirmation>
		</section>
	);
};

export default EntityList;
