import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
	loadBarometer,
	loadRegistrations,
} from 'core/store/well-being/well-being.actions';

import Loading from 'components/Loading/Loading';
import { WellBeingForm } from 'components/WellBeing';

const WellBeingDetail = () => {
	const { id } = useParams();

	const dispatch = useDispatch();

	const { wellBeingLoading, wellBeing, registrations, missing } = useSelector(
		state => state.WellBeingReducer,
	);

	useEffect(() => {
		if (id) {
			dispatch(loadBarometer(id));
			dispatch(loadRegistrations(id));
		}
	}, [dispatch]);

	return wellBeingLoading || (id && !wellBeing) ? (
		<Loading large centered />
	) : (
		<WellBeingForm
			object={wellBeing}
			registrations={registrations}
			missing={missing}
		/>
	);
};

export default WellBeingDetail;
