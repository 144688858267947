import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { INPUT_TYPES } from 'core/constants';
import { getEntityTypeDetails } from 'core/helpers/entities/entity.helper';
import { useForm } from 'core/hooks/form/useForm';
import { useSubmit } from 'core/hooks/submit/useSubmit';
import { postForm, postSubmission } from 'core/store/entities/entity.actions';
import { loadToppings } from 'core/store/toppings/topping.actions';

import { Textarea } from 'components/Form';
import { Col, Row } from 'components/Grid';
import Loading from 'components/Loading/Loading';
import Modal from 'components/Modal/Modal';

import Selector from '../Selector/Selector';
import SubscriptionTable from '../SubscriptionTable/SubscriptionTable';
import TrainingDay from '../TrainingDay/TrainingDay';

const TrainingRegistration = ({ list, type, id }) => {
	const dispatch = useDispatch();
	const { handleSubmit } = useSubmit();

	const { register, setValue, getValue } = useForm();

	const { permissions } = useSelector(state => state.authReducer);
	const { toppings, toppingsLoading } = useSelector(
		state => state.toppingReducer,
	);

	const { entity } = useSelector(state => state.entityReducer);

	const [selected, setSelected] = useState(null);
	const [order, setOrder] = useState(null);
	const [valid, setValid] = useState(false);

	useEffect(() => {
		dispatch(loadToppings());
	}, [dispatch, type, id]);

	useEffect(() => {
		if (order) {
			setValid(order.every(x => x.isValid));
		}
	}, [order]);

	useEffect(() => {
		if (selected) {
			setOrder(
				selected?.submissions.order?.map(x => {
					return {
						...x,
						bread: !x.bread,
					};
				}) ?? [],
			);
			setValue('remark', selected?.submissions?.remark ?? '');
		}
	}, [selected]);

	const submitForm = async () => {
		const body = {
			trainingId: id,
			userId: selected.user.id,
			results: order
				.filter(x => x.bread !== undefined)
				.map(({ bread, date, size, topping, extra }) => {
					return {
						date: date,
						bread: !bread,
						...(!bread
							? {
									size: size,
									extra: extra ?? false,
									topping: topping,
								}
							: {}),
					};
				}),
			remark: getValue('remark'),
		};

		await handleSubmit(postForm(body));

		await handleSubmit(
			postSubmission(type, id, selected.user.confirmed, selected.user.id),
		);
		setSelected(null);
		setOrder(null);
		setValue('remark', '');
	};

	return toppingsLoading ? (
		<Loading />
	) : (
		<div>
			<SubscriptionTable
				list={list}
				title={getEntityTypeDetails(type).title.toLowerCase()}
				id={id}
				setSelected={setSelected}
				permissions={permissions}
			/>

			<Modal
				close={() => {
					setSelected(null);
					setOrder(null);
					setValue('remark', '');
				}}
				show={!!selected}
				title={`Inschrijving voor ${selected?.user.firstName} ${selected?.user.lastName}`}
				actions={
					permissions.includes('forms_update')
						? [
								{
									buttonType: 'primary',
									action: () => submitForm(),
									label: 'Bewaren',
									disabled: entity?.days.every(
										x => x.bread === 0,
									)
										? selected?.user.confirmed === null
										: selected?.user.confirmed === true
											? !valid
											: selected?.user.confirmed === null,
								},
							]
						: []
				}
				medium>
				<Selector selected={selected} setSelected={setSelected} />

				{selected && selected.user.confirmed && order ? (
					<Row>
						{entity?.days.map((day, i, days) => (
							<Col
								md={6}
								lg={
									days.length > 2
										? 4
										: days.length > 1
											? 6
											: 12
								}
								key={day.date}>
								<TrainingDay
									day={day}
									order={order.find(x => x.date === day.date)}
									setOrder={setOrder}
									toppings={toppings}
								/>
							</Col>
						))}

						<Col md={12}>
							<Textarea
								{...register('remark', {
									type: INPUT_TYPES.TEXTAREA,
									name: 'remark',
									label: 'Opmerking',
									validations: [],
									value: '',
								})}
							/>
						</Col>
					</Row>
				) : null}
			</Modal>
		</div>
	);
};

export default TrainingRegistration;
