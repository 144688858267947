import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEntityTypeDetails } from 'core/helpers/entities/entity.helper';
import { useSubmit } from 'core/hooks/submit/useSubmit';
import {
	loadEntityForm,
	postForm,
	postSubmission,
} from 'core/store/entities/entity.actions';

import { Button, Form } from 'components/Form';
import Modal from 'components/Modal/Modal';

import SubscriptionTable from '../SubscriptionTable/SubscriptionTable';

const EventRegistration = ({ list, type, id }) => {
	const dispatch = useDispatch();
	const { handleSubmit } = useSubmit();

	const { permissions } = useSelector(state => state.authReducer);
	const { form } = useSelector(state => state.entityReducer);

	const [selected, setSelected] = useState(null);
	const [values, setValues] = useState(null);
	const [valid, setValid] = useState(false);

	useEffect(() => {
		dispatch(loadEntityForm(type, id));
	}, [dispatch]);

	const submitForm = async () => {
		if (Object.keys(form ?? {}).length > 0) {
			const body = {
				formId: form.id,
				userId: selected.user.id,
				values: form.fields.map(x => {
					const value = values[x.name];

					return {
						id: x.id,
						value: value,
					};
				}),
			};

			await handleSubmit(postForm(body));
		}

		await handleSubmit(
			postSubmission(type, id, selected.user.confirmed, selected.user.id),
		);
		setSelected(null);
	};

	return (
		<div>
			<SubscriptionTable
				list={list}
				title={getEntityTypeDetails(type).title.toLowerCase()}
				id={id}
				setSelected={setSelected}
				permissions={permissions}
			/>

			<Modal
				close={() => setSelected(null)}
				show={!!selected}
				title={`Inschrijving voor ${selected?.user.firstName} ${selected?.user.lastName}`}
				actions={
					permissions.includes('forms_update')
						? [
								{
									buttonType: 'primary',
									action: () => submitForm(),
									label: 'Bewaren',
									disabled:
										Object.keys(form ?? {}).length === 0
											? selected?.user.confirmed === null
											: selected?.user.confirmed === true
												? !valid
												: selected?.user.confirmed ===
													null,
								},
							]
						: []
				}>
				<div
					className='btn__group full--flex'
					style={{ marginBottom: '5rem' }}>
					<Button
						buttonStyle={
							selected?.user.confirmed === true ||
							selected?.user.confirmed === null
								? 'primary'
								: 'secondary'
						}
						label='Aanwezig'
						className='success'
						type='button'
						onClick={() =>
							setSelected(prevState => ({
								...prevState,
								user: {
									...prevState.user,
									confirmed: true,
								},
							}))
						}
					/>
					<Button
						buttonStyle={
							selected?.user.confirmed === false ||
							selected?.user.confirmed === null
								? 'primary'
								: 'secondary'
						}
						label='Afwezig'
						className='danger'
						type='button'
						onClick={() =>
							setSelected(prevState => ({
								...prevState,
								user: {
									...prevState.user,
									confirmed: false,
								},
							}))
						}
					/>
				</div>

				{selected &&
				selected.user.confirmed &&
				Object.keys(form).length ? (
					<Form
						fields={
							form?.fields.map(field => {
								const submission = selected?.submissions?.find(
									sub => sub.field.name === field.name,
								);

								return {
									...field,
									value: submission
										? field.type === 'checkbox'
											? submission.value === '1'
											: submission.value
										: '',
									required: field.required === '1',
									placeholder: field.placeholder ?? '',
								};
							}) ?? []
						}
						submitOnClick={() => ''}
						showDefaultButtons={false}
						values={values => setValues(values)}
						valid={valid => setValid(valid)}
					/>
				) : null}
			</Modal>
		</div>
	);
};

export default EventRegistration;
