import React, { useEffect } from 'react';
import { toShortDate } from 'core/services/date';
import { arrayOf, string } from 'prop-types';

import { InputCheckbox, RangePicker, Textarea } from 'components/Form';

const AddDateInfo = ({
	id,
	dates,
	dateInfo,
	setDateInfo,
	type,
	disabled = false,
}) => {
	useEffect(() => {
		const temp = {};
		dates.forEach(date => {
			if (!temp[date]) {
				if (dateInfo[date]) {
					temp[date] = dateInfo[date];
				} else {
					const item = getLastDateBefore(date);

					temp[date] = {
						time: {
							from:
								item && item.time.from
									? item.time.from
									: '17:30',
							until:
								item && item.time.until
									? item.time.until
									: '21:00',
						},
						bread: true,
						reason: '',
					};
				}
			}
		});

		setDateInfo(orderByDate(temp));
	}, [dates]);

	const getLastDateBefore = targetDate => {
		const dates = Object.keys(dateInfo).sort();
		let lastDate = null;

		for (let date of dates) {
			if (new Date(date).getTime() < new Date(targetDate).getTime()) {
				lastDate = date;
			} else {
				break;
			}
		}

		return lastDate ? dateInfo[lastDate] : null;
	};

	const orderByDate = data => {
		// Convert the object into an array of key-value pairs
		const entries = Object.entries(data);

		// Sort the array by date
		entries.sort((a, b) => new Date(a[0]) - new Date(b[0]));

		// Convert the sorted array back into an object
		return Object.fromEntries(entries);
	};

	const handleChange = (type, date, value) => {
		setDateInfo(prevState => ({
			...prevState,
			[date]: {
				...prevState[date],
				[type]: value,
			},
		}));
	};

	return (
		<div className='add-date-info'>
			<div className='add-date-info__day'>
				{Object.keys(dateInfo).map(date => {
					const obj = dateInfo[date];

					return (
						<div key={date} className='add-date-info__day--item'>
							<RangePicker
								id={id}
								onChange={value =>
									handleChange('time', date, value)
								}
								label={toShortDate(date)}
								description='Maak start- en einduur gelijk voor een open einde'
								value={obj.time}
								errors={[]}
								setFocused={() => ''}
								setTouched={() => ''}
								disabled={disabled}
								required
							/>
							{type === 'training' ? (
								<>
									<InputCheckbox
										onChange={value =>
											handleChange('bread', date, value)
										}
										name='bread'
										label='Bestel broodjes'
										value={obj.bread}
										setTouched={() => ''}
										disabled={disabled}
									/>
									{!obj.bread ? (
										<Textarea
											onChange={value =>
												handleChange(
													'reason',
													date,
													value,
												)
											}
											name='reason'
											label='Reden'
											description='Waarom moeten er geen broodjes besteld worden?'
											rows={2}
											value={obj.reason}
											setFocused={() => ''}
											setTouched={() => ''}
											disabled={disabled}
										/>
									) : null}
								</>
							) : null}
						</div>
					);
				})}
			</div>
		</div>
	);
};

AddDateInfo.defaultProps = {
	dates: [],
	type: 'training',
};

AddDateInfo.propTypes = {
	dates: arrayOf(string),
	type: string,
};

export default AddDateInfo;
