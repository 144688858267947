import { ACTION_TYPES } from 'core/constants/action.constants/action.constants';
import { dispatchAction } from 'core/services/actions';
import { successMessageService } from 'core/services/message';

export const loadInstructors = () => () => {
	dispatchAction({
		type: ACTION_TYPES.INSTRUCTORS_LOAD,
		payload: { instructorsLoading: true },
		meta: {
			action: {
				effect: {
					url: '/admin/instructors',
					method: 'get',
				},
				commit: { type: ACTION_TYPES.INSTRUCTORS_LOAD_COMMIT },
			},
			followup: [],
		},
	});
};

export const handleInstructor = (id, data) => () => {
	dispatchAction({
		type: ACTION_TYPES.INSTRUCTORS_POST,
		payload: { instructorsLoading: true },
		meta: {
			action: {
				effect: {
					url: `/admin/instructors${id ? `/${id}` : ''}`,
					body: data,
					method: id ? 'PUT' : 'POST',
				},
				commit: {
					type: ACTION_TYPES.INSTRUCTORS_POST_COMMIT,
					payload: { id },
				},
			},
			toast: {
				type: 'success',
				text: successMessageService(
					'instructor',
					id ? 'updated' : 'created',
				),
			},
			followup: [],
		},
	});
};

export const handleResetInstructor = id => () => {
	dispatchAction({
		type: ACTION_TYPES.INSTRUCTORS_PUT,
		payload: { instructorsLoading: true },
		meta: {
			action: {
				effect: {
					url: `/admin/instructors/${id}/reset`,
					method: 'PUT',
				},
				commit: {
					type: ACTION_TYPES.INSTRUCTORS_PUT_COMMIT,
					payload: { id },
				},
			},
			toast: {
				type: 'success',
				text: successMessageService('instructor', 'reset'),
			},
			followup: [],
		},
	});
};

export const deleteInstructor = id => () => {
	dispatchAction({
		type: ACTION_TYPES.INSTRUCTORS_DELETE,
		payload: {},
		meta: {
			action: {
				effect: {
					url: `/admin/instructors/${id}`,
					method: 'delete',
				},
				commit: {
					type: ACTION_TYPES.INSTRUCTORS_DELETE_COMMIT,
					payload: { id },
				},
			},
			toast: {
				type: 'success',
				text: successMessageService('instructor', 'deleted'),
			},
			followup: [],
		},
	});
};
