import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { stripTags } from 'core/helpers/stripTags';
import { toLongDate } from 'core/services/date';
import { loadMailbox } from 'core/store/mailbox/mailbox.actions';

import Loading from 'components/Loading/Loading';
import Modal from 'components/Modal/Modal';

const Send = () => {
	const dispatch = useDispatch();
	const context = useOutletContext();

	const [modal, setModal] = useState(null);

	const { entity } = useSelector(state => state.entityReducer);
	const { mailboxesLoading, mailboxes } = useSelector(
		state => state.mailboxReducer,
	);

	useEffect(() => {
		dispatch(loadMailbox(context?.type, context?.id));
	}, [dispatch]);

	return (
		<section>
			<h2>{entity?.name}</h2>
			{mailboxesLoading ? (
				<Loading />
			) : (
				<ul className='mailbox__messages'>
					{mailboxes?.length ? (
						mailboxes.map(mailbox => (
							<li
								className='mailbox__messages--message'
								key={mailbox.id}
								onClick={() => setModal(mailbox)}>
								<div className='header'>
									<div className='from'>
										{mailbox.user.firstName}{' '}
										{mailbox.user.lastName}
									</div>
									<div className='date'>
										<FontAwesomeIcon
											icon={faCalendarAlt}
											fixedWidth
										/>
										{toLongDate(mailbox.createdAt)}
									</div>
								</div>
								<div className='title'>{mailbox.title}</div>
								<div className='description'>
									{stripTags(mailbox.content)}
								</div>
							</li>
						))
					) : (
						<li className='empty-list'>
							Geen data om weer te geven
						</li>
					)}
				</ul>
			)}

			<Modal
				title={modal?.title}
				show={!!modal}
				close={() => setModal(null)}
				large>
				<div className='mailbox__email'>
					<div className='mailbox__email--header'>
						<div className='mailbox__email--from'>
							<div className='mailbox__email--from_name'>
								{modal?.user.firstName} {modal?.user.lastName}
							</div>
							<div className='mailbox__email--from_email'>
								{modal?.user.email}
							</div>
						</div>
						<div className='mailbox__email--date'>
							<FontAwesomeIcon icon={faCalendarAlt} />
							{toLongDate(modal?.createdAt)}
						</div>
					</div>
					<div className='mailbox__email--to'>
						<div className='mailbox__email--to_label'>Aan:</div>
						<div className='mailbox__email--to_names'>
							{modal?.to.map(x => (
								<div title={x.email}>
									{x.firstName} {x.lastName}
								</div>
							))}
						</div>
					</div>
					<div
						className='mailbox__email--content'
						dangerouslySetInnerHTML={{ __html: modal?.content }}
					/>
				</div>
			</Modal>
		</section>
	);
};

export default Send;
