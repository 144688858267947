import { applicationReducer } from 'core/store/application/application.reducer';
import { authReducer } from 'core/store/authentication/auth.reducer';
import { employeeReducer } from 'core/store/employees/employee.reducers';
import { entityReducer } from 'core/store/entities/entity.reducers';
import { instructorReducer } from 'core/store/instructors/instructor.reducers';
import { mailboxReducer } from 'core/store/mailbox/mailbox.reducers';
import { movingMissionReducer } from 'core/store/moving-mission/moving-mission.reducers';
import { organiserReducer } from 'core/store/organisers/organiser.reducers';
import { snackbarReducer } from 'core/store/snackbar/snackbar.reducer';
import { staticsReducer } from 'core/store/statics/statics.reducer';
import { tagReducer } from 'core/store/tags/tag.reducers';
import { toppingReducer } from 'core/store/toppings/topping.reducers';
import { WellBeingReducer } from 'core/store/well-being/well-being.reducers';
import { combineReducers } from 'redux';

export default combineReducers({
	authReducer,
	applicationReducer,
	snackbarReducer,
	employeeReducer,
	organiserReducer,
	instructorReducer,
	tagReducer,
	entityReducer,
	toppingReducer,
	WellBeingReducer,
	staticsReducer,
	movingMissionReducer,
	mailboxReducer,
});
