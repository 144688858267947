import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSubmit } from 'core/hooks/submit/useSubmit';
import {
	loadPermissions,
	loadRoles,
	updatePermissions,
} from 'core/store/application/application.actions';

import { InputCheckbox } from 'components/Form';
import { AdminHeader } from 'components/Layouts/AdminLayout/AdminHeader/AdminHeader';
import Loading from 'components/Loading/Loading';

import './Permissions.scss';

const Permissions = () => {
	const dispatch = useDispatch();
	const { handleSubmit } = useSubmit();

	const { permissions, permissionsLoading, roles, rolesLoading } =
		useSelector(state => state.applicationReducer);

	useEffect(() => {
		dispatch(loadPermissions());
		dispatch(loadRoles());
	}, [dispatch]);

	const handleUpdate = async (roleId, permission) => {
		const data = roles.map(role => {
			if (role.id !== roleId) return role;

			if (role.permissions.some(x => x.id === permission.id)) {
				return {
					...role,
					permissions: role.permissions.filter(
						p => p.id !== permission.id,
					),
				};
			} else {
				return {
					...role,
					permissions: [...role.permissions, permission],
				};
			}
		});

		await handleSubmit(updatePermissions(data));
	};

	return (
		<>
			<AdminHeader title='Permissies beheren' />

			<section className='permissions__body'>
				{permissionsLoading || rolesLoading ? (
					<Loading centered large />
				) : (
					<table className='admin-permissions'>
						<thead>
							<tr>
								<th>Naam</th>
								{roles.map(role => (
									<th
										key={`role-${role.id}`}
										style={{
											width: '9.3rem',
											textAlign: 'center',
										}}>
										{role.displayName}
									</th>
								))}
							</tr>
						</thead>
						<tbody>
							{permissions?.map((permission, i) => (
								<tr key={`permission-${permission.id}-${i}`}>
									<td>
										<b>{permission.displayName}</b>
										<br />
										{permission.description}
									</td>

									{roles.map(role => (
										<td
											key={`role-${role.id}-${permission.id}`}
											valign='center'
											style={{
												width: '9.3rem',
												textAlign: 'center',
												verticalAlign: 'middle',
											}}>
											<InputCheckbox
												id={`role_${role.id}-${permission.id}`}
												onChange={() =>
													handleUpdate(
														role.id,
														permission,
													)
												}
												setTouched={() => ''}
												name=''
												value={
													role.permissions
														? !!role.permissions.find(
																rolePermission =>
																	rolePermission.name ===
																	permission.name,
															)
														: false
												}
												disabled={
													role.name === 'admin' ||
													role.name === 'dev'
												}
												style={{ marginBottom: 0 }}
											/>
										</td>
									))}
								</tr>
							))}
						</tbody>
					</table>
				)}
			</section>
		</>
	);
};

export default Permissions;
