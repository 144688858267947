export const rolesFormData = (roles, options = null) => {
	return [
		{
			type: 'hidden',
			name: 'user_id',
			value: options?.userId ?? '',
		},
		{
			type: 'select',
			name: 'role_id',
			label: 'Rol',
			placeholder: 'Selecteer de rol',
			description: 'Verwijder de rol om de standaard rol te herstellen',
			required: false,
			validations: [],
			options: roles
				.filter(
					x =>
						x.name === 'admin' ||
						x.name === 'dev' ||
						x.name === 'editor',
				)
				.map(x => {
					return { label: x.displayName, value: x.id };
				}),
			isClearable: true,
			value: options?.roleId ?? '',
		},
	];
};
